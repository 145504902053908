import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Titlebar from "../components/Titlebar";
import "./Careers.css";

const Careers = () => {
  return (
    <div>
       <Titlebar title="Careers" photobanner="/assets/images/title_careers.jpg"/>
      <div className="careers">
        <Container>
          <Row>
            <Col>
              <h1>Career Page</h1>
             <p>
                  We focus on nurturing our team and providing our team an
                  environment that is conductive to creative thought. We focus on
                  eliminating stress and pressure so our team can think clearly and
                  creatively.We specialize in hiring people who have the drive to
                  succeed and the will to implement the discipline required to
                  succeed.
             </p>
              
            <p>
                  If you are interested in our services please contact us via the
                  "contact us" tab or by sending us your resume to
                  info@voltadynamics.com and we will ensure we assign you a
                  recruiter who will reach out to you directly via phone and/or
                  email to better understanding your specific career goals, needs,
                  and objectives and will then explore all the roles we have
                  available that will meet your expectations in your career change
                  and growth.
            </p>
              
            <p>
                  Our recruiters are aligned by specialty skills and area to better
                  understand the trends and opportunities in your industry. During
                  the initial discussion, they will learn more specifics about your
                  background and skill set to determine what job opportunities will
                  be most interesting to you and why and will then only send you the
                  job available that are applicable to your needs.
            </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Careers;
