import React from "react";
import { Container, Row, Col, Card, CardColumns } from "react-bootstrap";
import Titlebar from "../components/Titlebar";
import "./Services.css";

const Services = () => {
  return (
    <div>
       <Titlebar title="Services" photobanner="/assets/images/title_services.jpg"/>
      <div className="services">
              {/* <h1>Service Page</h1> */}

        <div className="grey">  
        <Container>
       
          <Row>
            <Col>

              <CardColumns>
                <Card>
                  <Card.Img
                    variant="top"
                    src="../assets/images/service01.jpg"
                  />
                  <Card.Body>
                    <Card.Title>Software Development</Card.Title>
                    <hr />
                    <Card.Text>
                      We provide Project Management, Business Process Analysis,
                      Systems Design & Development, Database Design &
                      Development, Applications Design & Development, Web Design
                      & Development.
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="../assets/images/service02.jpg"
                  />
                  <Card.Body>
                    <Card.Title>IT Infrastructure & Cyber Security</Card.Title>
                    <hr />
                    <Card.Text>
                      We provide LAN/WAN Administration, Systems Administration,
                      Network Monitoring & Management, Technology Refresh,
                      Systems Configuration Management, Helpdesk Support, and
                      Cyber Security services. We also provide protection of
                      computer systems from the theft or damage to hardware,
                      software or the information on them, as well as from
                      disruption or misdirection of the services they provide,
                      IT Security Support, FISMA Reporting and Compliance,
                      Security A&A and C&A.
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="../assets/images/service03.jpg"
                  />
                  <Card.Body>
                    <Card.Title>Global Strategic Advisory</Card.Title>
                    <hr />
                    <Card.Text>
                      We provide Strategic Advisory such as strategic guidance,
                      actionable insights, and comprehensive implementation and
                      management for International, State and Local Government
                      entities. Assists public and private sector organizations
                      in Strategic Planning, Strategic Placements, Strategic
                      Audits, Public and private sector strategic planning.
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="../assets/images/service04.jpg"
                  />
                  <Card.Body>
                    <Card.Title>Financial Services</Card.Title>
                    <hr />
                    <Card.Text>
                      We provide non-banking financial services such financial
                      and compliance audits to federal, state and local
                      governments, Economic Impact Study Analysis, Market
                      Research and Analysis, Risk Assessment Evaluation,
                      Compliance reviews, Cost management, Financial planning
                      and budgeting, Management reporting, Performance
                      management improvement, Financial risk management and
                      treasury function development, Redesign of the finance
                      function, and Financial modeling. We provide non-banking
                      financial services such financial and compliance audits to
                      federal, state and local governments, Economic Impact
                      Study Analysis, Market Research and Analysis, Risk
                      Assessment Evaluation, Compliance reviews, Cost
                      management, Financial planning and budgeting, Management
                      reporting, Performance management improvement, Financial
                      risk management and treasury function development,
                      Redesign of the finance function, and Financial modeling.
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="../assets/images/service05.jpg"
                  />
                  <Card.Body>
                    <Card.Title>IV&V and Acquisition Management</Card.Title>
                    <hr />
                    <Card.Text>
                      We provide Requirements Analysis & Management, Quality
                      Control & Assurance, Testing & Evaluation, Program
                      Measures & Metrics, Configuration Management, Systems
                      Integration, Market Analysis & Research, Program
                      Assessments & Evaluations, and IT Workforce Requirements &
                      Analysis.
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="../assets/images/service06.jpg"
                  />
                  <Card.Body>
                    <Card.Title>Program Management Operations</Card.Title>
                    <hr />
                    <Card.Text>
                      We provide Program Management, IT Capital Planning &
                      Budgeting, Earned Value Management, Data Management, Cost
                      Estimating & Modeling, Change Management, and IT
                      Communications.
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="../assets/images/service07.jpg"
                  />
                  <Card.Body>
                    <Card.Title>Record Management</Card.Title>
                    <hr />
                    <Card.Text>
                      We provide Library operations and support, Records
                      organization and management, Historical Records research,
                      Digitization and retrieval system development, Records
                      program audits and compliance assurance, Special
                      collection development and management, Abstracting,
                      indexing, editing, and cataloging, Electronic
                      dissemination of information via the Internet, Computer
                      systems integration and operation, Optical disk systems
                      and scanning services, Database development and
                      maintenance, Library and records management system
                      assessments, and purchase
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="../assets/images/service08.jpg"
                  />
                  <Card.Body>
                    <Card.Title>Energy</Card.Title>
                    <hr />
                    <Card.Text>
                      We aim to identify, develop, own and operate solar
                      electricity generation plants. Volta Dynamics possesses
                      significant knowledge of green and renewable energy
                      markets, energy technologies, project development,
                      implementation and operation of power generation plants.
                      Through the creation of sustainable energy sources,
                      employment opportunities and economic development, diverse
                      communities benefit from an enhanced quality of life - at
                      work and at home. We are fully committed to partnering
                      with communities to become good stewards of their
                      environments as successful green energy projects transform
                      and benefit neighborhoods, towns, and cities worldwide.
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="../assets/images/service09.jpg"
                  />
                  <Card.Body>
                    <Card.Title>International Trade Facilitators</Card.Title>
                    <hr />
                    <Card.Text>
                      We work in the International Trade arena to facilitate the
                      exporting and importing of goods all over the world. We
                      bring a broad range of experience and network to offer
                      services that provide end to end solutions in the areas of
                      import and export and foreign investments. We can help you
                      buy or sell abroad. Our experienced team will assist you
                      in any way we can to make your foreign business
                      transactions, both import and export, smooth and
                      trouble-free.
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="../assets/images/service10.jpg"
                  />
                  <Card.Body>
                    <Card.Title>Cloud Services</Card.Title>
                    <hr />
                    <Card.Text>
                      We advise, implement, operate in the areas of Cloud
                      Strategy, Cloud Implementation and Cloud Management. Volta
                      Dynamics helps organizations successfully migrate
                      significant workloads to the cloud. These companies
                      benefited from substantial IT costs savings, as well as
                      improvements in productivity, business agility, and
                      operational resilience.
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="../assets/images/service11.jpeg"
                  />
                  <Card.Body>
                    <Card.Title>Data Management</Card.Title>
                    <hr />
                    <Card.Text>
                      We development and execution of architectures, policies,
                      practices and procedures that properly manage the full
                      data lifecycle needs of an enterprise.
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="../assets/images/service12.jpeg"
                  />
                  <Card.Body>
                    <Card.Title>Staffing</Card.Title>
                    <hr />
                    <Card.Text>
                      We provide workforce solutions to businesses which include
                      full-time, temporary, and part-time employment in a wide
                      range of positions.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </CardColumns>
            </Col>
          </Row>
        </Container>
        </div>
      </div>
    </div>
  );
};

export default Services;
