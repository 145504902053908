import React from "react";
import { Container } from "react-bootstrap";
import './Navcomp.css'
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";

const Navcomp = () => {
  return (
      <div>
        <Navbar expand="lg" fixed="top">
          <Container>
          <Navbar.Brand href="/">Volta Dynamics</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto ">
              <Nav.Link href="/">HOME</Nav.Link>
              <Nav.Link href="/about">ABOUT US</Nav.Link>
              <Nav.Link href="/services">SERVICES</Nav.Link>
              <Nav.Link href="/careers">CAREERS</Nav.Link>
              <Nav.Link href="/teaming">TEAMING</Nav.Link>
              <Nav.Link href="/news">NEWS AND EVENTS</Nav.Link>
              <Nav.Link href="/contact">CONTACTS</Nav.Link>
            </Nav>
            {/* <Form inline>
      <FormControl type="text" placeholder="Search" className="mr-sm-2" />
      <Button variant="outline-success">Search</Button>
    </Form> */}
          </Navbar.Collapse>
      </Container>
        </Navbar>
    </div>
  );
};

export default Navcomp;
