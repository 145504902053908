import React, { Component } from 'react'
import { Container, Row , Col} from "react-bootstrap";
import Titlebar from '../components/Titlebar';
import './News.css';

class News extends Component {
    render() {
        return (
            <div>
               <Titlebar title="News and Events" photobanner="/assets/images/title_news.jpg"/>
                      <div className="news">
        <Container>
          <Row>
            <Col>
              <h1>News Page</h1>
            </Col>
          </Row>
        </Container>
      </div>
            </div>
        )
    }
}

export default News;