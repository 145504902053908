import React, { Component } from "react";
import {
  Container,
  Col,
  Row,
  Form,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <div>
        <div className="footer-color">
          <Container>
            <Row>
              <Col>
                <h6>About Us</h6>
                <p>
                  Volta Dynamics is a small minority-owned technical &
                  management Support services firm.
                </p>
              </Col>
              <Col>
                <h6>Navigation</h6>
                <ul>
                  <li>About</li>
                  <li>Services</li>
                  <li>Employment</li>
                  <li>News and Events</li>
                  <li>Contact</li>
                </ul>
              </Col>
              <Col>
                <h6>Contact</h6>
                301-821-6868 info@voltadynamics.com Mon - Fri 9:00 - 5:00 Volta
                Dynamics 2228 Chapman Road Hyattsville, MD 20783
              </Col>
              <Col>
                <h6>Subscribe</h6>
                <p>Get the latest updates and offers.</p>
                <Form inline>
                  <InputGroup className="mb-2 mr-sm-2">
                    <InputGroup.Prepend>
                      <InputGroup.Text>@</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      id="inlineFormInputGroupUsername2"
                      placeholder="Username"
                    />
                  </InputGroup>

                  <Button type="submit" className="mb-2">
                    Go
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="copyrigth">
            <Row>
                <Col>
                <Container>
               <p >Copyright © 2020. All Rights Reserved, website designed by Ad-Rod Media</p> 

                </Container>
                </Col>

            </Row>

        </div>
      </div>
    );
  }
}

export default Footer;
