import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Titlebar from "../components/Titlebar";
import "./Contact.css";

class Contact extends Component {
  render() {
    return (
      <div>
         <Titlebar title="Contact" photobanner="/assets/images/title_contact.jpg"/>
        <div className="contact">
          <Container>
            <Row>
              <Col>
                <h2>How can we assist you?</h2>
                <p>
                  Despite the UI, We thought of the User experience, With
                  attached From Layouts you can simply categories Important
                  fields and prioritize them.
                </p>

                <p>
                  Fill up this form to contact us if you have any futher
                  questions
                </p>

                <Form>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="FIRST NAME"
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="LAST NAME"
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Group controlId="formGridAddress1">
                    <Form.Control size="lg" type="email" placeholder="EMAIL" />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="MESSAGE"
                    />
                  </Form.Group>
                  <span>
                    I hereby certify that the information above is true and
                    accurate.
                  </span>

                  <Button type="submit" className="mb-2">
                    Submit
                  </Button>
                </Form>
              </Col>
              <Col>
                <p>
                  Volta Dynamics <br />
                  2228 Chapman Road <br />
                  Hyattsville, MD 20783
                </p>

                <b>We Are Open</b>
                <p>Open hours: 8.00-5.00 Mon-Fri</p>

                <b>Phone</b>
                <p>Telephone: 301-821-6868</p>

                <p>E-mail: info@voltadynamics.com</p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Contact;
