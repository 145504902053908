import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Titlebar from "../components/Titlebar";
import "./Team.css";

const Team = () => {
  return (
    <div>
       <Titlebar title="Teaming" photobanner="/assets/images/title_teaming.jpg"/>
      <div className="team">
        <Container>
          <Row>
            <Col>
              <h1>Teaming Page</h1>
             <p>
                  Volta Dynamics is always looking for reputable firms to team with
                  on opportunities. Through our strong and established
                  sub-contracting and teaming relationships with other companies, we
                  have created cost effective and proven solutions that better meet
                  the needs of our clients.
             </p>
              
             <p>
                  Volta Dynamics looks forward to meeting new firms, creating
                  stronger teams, and lasting partnerships. Please send all teaming
                  correspondence to info@voltadynamics.com
             </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Team;
