import './App.css';
import React,{ useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navcomp from './components/Navcomp';
import Footer from './components/Footer';

import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Careers from './pages/Careers';
import Teaming from './pages/Team';
import News from './pages/News';
import Contact from './pages/Contact';


import useWindowScrollPosition from "@rehooks/window-scroll-position";

function TestNav() {
  const [change, setChange] = useState(false);
  const changePosition = 80;

  let position = useWindowScrollPosition();
  // position == { x: 0, y: 0 }

  if (position.y > changePosition && !change) {
    setChange(true);
  }

  if (position.y <= changePosition && change) {
    setChange(false);
  }

  let style = {
    backgroundColor: change ? "#0B5BE0" : "transparent",
    transition: "400ms ease",
    height: "80px",
    position: "fixed",
    right: 0,
    left: 0,
    top: 0
  };

  return (
    <div style={style}>
      {/* <h1>Logo</h1> */}
    </div>
  );
}

function Spacer() {
  let style = {
    // height: "200vh"
  };

  return <div style={style} />;
}

function App() {
  return (
    <Router>
      <div>
        <Navcomp />
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/services" component={Services} />
        <Route path="/careers" component={Careers} />
        <Route path="/teaming" component={Teaming} />
        <Route path="/news" component={News} />
        <Route path="/contact" component={Contact} />
        <TestNav />
        <Footer />

      </div>
    </Router>
  );
}

export default App;
