import React, { Component } from "react";
import { Container, Row , Col} from "react-bootstrap";
import Titlebar from '../components/Titlebar';
import './About.css';

class About extends Component {
  render() {
    return (
      <div>
         <Titlebar title="About Us" photobanner="/assets/images/title_about.jpg"/>
        <div className="about">
          <Container>
            <Row>
              <Col sm={4}>
                <h1>Who we are and what we do</h1>
              </Col>
              <Col sm={8}>
                <p>
                    Volta Dynamics is a minority-owned Technical, Management and
                    Strategic support services firm which provides technical,
                    research & management support services to a variety of clients
                    including private industry, federal, state and local government.
                    Volta Dynamics has highly trained interdisciplinary key
                    personnel and staff members engaged in various projects. 
                </p>
                
                <p>
                    We havean extensive roster of IT professionals that are thoroughly
                    screened, trained, certified, and ready to take on your
                    important projects.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default About;
