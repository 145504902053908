import React, { Component } from 'react';
import './Titlebar.css'

export default class Titlebar extends Component {
    render() {
        return (
            <div className="overlay"> 
                <div className="header"
                    style={{
                        backgroundImage: `url(${this.props.photobanner})`
                    }}>
                    <h1>{this.props.title}</h1>
                    <p><a href="/home">Home</a>  / {this.props.title}</p>

                </div>
            </div>

        )
    }
}
